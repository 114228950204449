import { createSlice } from '@reduxjs/toolkit'

export const jackpotSlice = createSlice({
    name: 'jackpot',
    initialState: {
      value: [],
    },
    reducers: {
        setJackpot: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setJackpot } = jackpotSlice.actions;

export default jackpotSlice.reducer;
