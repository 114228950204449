import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Textarea, Typography } from "@material-tailwind/react";



import axios from 'axios';
import { toast } from "react-toastify";
import { setAccountBalance } from "../features/accountBalanceSlice";
axios.defaults.withCredentials = true;



const Rain = (props) => {
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();

    const accountBalance = useSelector((state) => state.accountBalance.value);
    const userId = useSelector((state) => state.userId.value);
    const phone = useSelector((state) => state.phone.value);

    const minimumRainAmount = JSON.parse(localStorage.getItem('minimumRainAmount'));
    const marketerStatus = JSON.parse(localStorage.getItem('marketerStatus'));


    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));

    const noOfPeopleRef = useRef();
    const amountPerPersonRef = useRef();
    const accountBalanceRef = useRef();
    const errRef = useRef();

    const [validNoOfPeople, setValidNoOfPeople] = useState(true);
    const [noOfPeopleFocus, setNoOfPeopleFocus] = useState(false);

    const [validAmountPerPerson, setValidAmountPerPerson] = useState(true);
    const [amountPerPersonFocus, setAmountPerPersonFocus] = useState(true);

    const [validAccountBalance, setValidAccountBalance] = useState(true);
    const [accountBalanceFocus, setAccountBalanceFocus] = useState(false);



    const [errMsg, setErrMsg] = useState([]);
    const [noOfPeopleError, setNoOfPeopleError] = useState('');
    const [amountPerPersonError, setAmountPerPersonError] = useState('');


    const [noOfPeople, setNoOfPeople] = useState(1);
    const [amountPerPerson, setAmountPerPerson] = useState(minimumRainAmount);

    const [rainProcessing, setRainProcessing] = useState(false);


    const countryCode = JSON.parse(localStorage.getItem('countryCode'));
    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
    }



    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }




    useEffect(() => {
        amountPerPersonRef.current.focus();
    }, [])





    useEffect(() => {
        if (parseFloat(noOfPeople) >= 1) {
            setValidNoOfPeople(() => true);
        } else {
            setValidNoOfPeople(() => false);
        }
    }, [amountPerPerson, minimumRainAmount, noOfPeople]);


    useEffect(() => {
        if (amountPerPerson < Number(minimumRainAmount)) {
            setValidAmountPerPerson(false);
        } else {
            setValidAmountPerPerson(true);
        }
    }, [amountPerPerson, minimumRainAmount])


    //consider marketer balances
    useEffect(() => {
        if (marketerStatus == true && (parseFloat(amountPerPerson) * parseFloat(noOfPeople)) > parseFloat(props.availableReferralBalance)) {
            setValidAccountBalance(() => false);
        } else {
            setValidAccountBalance(() => true);
        }
    }, [accountBalance, amountPerPerson, marketerStatus, noOfPeople, props.availableReferralBalance, validAccountBalance])


    //consider non-marketer balances
    useEffect(() => {

        if (marketerStatus == false && (parseFloat(amountPerPerson) * parseFloat(noOfPeople)) > parseFloat(accountBalance)) {
            setValidAccountBalance(() => false);
        } else {
            setValidAccountBalance(() => true);
        }
    }, [accountBalance, amountPerPerson, marketerStatus, noOfPeople, props.availableReferralBalance, validAccountBalance])




    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setRainProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/offerRainPlayer`,
                { amountPerPerson: amountPerPerson, noOfPeople: noOfPeople},
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            )
            dispatch(setAccountBalance(response.data.data.new_account_balance));
            setRainProcessing(() => false);
            props.handleOpenRain();
            successNotify('Rain has been offered successfully.');

        } catch (error) {
            setErrMsg(error.response.data.message);
            setRainProcessing(() => false);
            errorNotify(error.response.data.message);
            errRef.current.scrollIntoView();

        }

    }


    return (

        <div>



            <Card className="mx-auto w-full max-w-[24rem] my-4">
                <CardBody className="pt-0 flex flex-col gap-4">


                    <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                        {errMsg !== '' &&
                            <li>{errMsg}<br /></li>
                        }

                    </ul>


                    <Typography
                        id="validAccountBalanceNote"

                        variant="small"
                        color="red"
                        className={"mt-2 items-center gap-1 font-normal " + accountBalanceRef && !validAccountBalance ? "flex" : "hidden"}>
                        Insufficient account balance!
                    </Typography>


                    <Input
                        variant="outlined"
                        label="Amount per Person"
                        size="lg"
                        type="number"
                        id="amountPerPerson"
                        ref={amountPerPersonRef}
                        autoComplete="off"
                        aria-invalid={validAmountPerPerson ? "false" : "true"}
                        aria-describedby="amountPerPersonNote"
                        onChange={(e) => setAmountPerPerson(e.target.value)}
                        value={amountPerPerson}
                        onFocus={() => setAmountPerPersonFocus(true)}
                        onBlur={() => setAmountPerPersonFocus(false)}
                        error={!validAmountPerPerson ? true : false}
                        success={validAmountPerPerson ? true : false}
                        required
                    />

                    <Typography
                        id="amountPerPersonNote"
                        variant="small"
                        color="red"
                        className={"mt-2 items-center gap-1 font-normal " + amountPerPersonRef && !validAmountPerPerson ? "flex" : "hidden"}>
                        Must be greater than {formatAmount(minimumRainAmount)}!
                    </Typography>



                    <Input
                        variant="outlined"
                        label="Number of People"
                        size="lg"
                        type="number"
                        id="noOfPeople"
                        ref={noOfPeopleRef}
                        autoComplete="off"
                        aria-invalid={validNoOfPeople ? "false" : "true"}
                        aria-describedby="noOfPeopleNote"
                        onChange={(e) => setNoOfPeople(e.target.value)}
                        value={noOfPeople}
                        onFocus={() => setNoOfPeopleFocus(true)}
                        onBlur={() => setNoOfPeopleFocus(false)}
                        error={!validNoOfPeople ? true : false}
                        success={validNoOfPeople ? true : false}
                        required
                    />

                    <Typography
                        id="noOfPeopleNote"
                        variant="small"
                        color="red"
                        className={"mt-2 items-center gap-1 font-normal " + noOfPeopleRef && !validNoOfPeople ? "flex" : "hidden"}
                    >
                        Must be greater than 1! <br />
                    </Typography>





                </CardBody>
                <CardFooter className="pt-0">
                    {rainProcessing === true &&
                        <Button disabled={rainProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                            <span className="flex items-center justify-center gap-2 mx-auto">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        </Button>
                    }
                    {rainProcessing === false &&
                        <Button onClick={handleSubmit} disabled={!validAccountBalance || !validAmountPerPerson || !validNoOfPeople ? true : false} color='indigo' variant="gradient" fullWidth>
                            Submit
                        </Button>
                    }
                </CardFooter>
            </Card>

            <div className="grid grid-cols-1 p-2 gap-2 text-sm w-full text-orange-200 border rounded">
                <span className="w-full">
                    Minimum amount per person: <b>{formatAmount(minimumRainAmount)}</b>
                </span>
            </div>

        </div>
    )
}

export default Rain