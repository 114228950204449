import { createSlice } from '@reduxjs/toolkit'

export const rainSlice = createSlice({
    name: 'rain',
    initialState: {
        value: [],
    },
    reducers: {
        setRain: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setRain } = rainSlice.actions;

export default rainSlice.reducer;
