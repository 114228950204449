import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CrashNew from './CrashNew';
import { toast } from 'react-toastify';
import Options from './Options';
import {
    Button, Carousel, Dialog, DialogBody, DialogFooter, DialogHeader

} from '@material-tailwind/react';

import CookieConsent from "react-cookie-consent";
import MelbetTables from './melbet/MelbetTables';
import axios from 'axios';
import Marquee from 'react-fast-marquee';
import { setOpenMpesaPayment } from '../features/openMpesaPaymentSlice';
import { setOpenDeposit } from '../features/openDepositSlice';
import { setOpenDusupayPayment } from '../features/openDusupayPaymentSlice';
import { setOpenWingipayPayment } from '../features/openWingipayPaymentSlice';
axios.defaults.withCredentials = true;




const CrashGame = () => {
    const dispatch = useDispatch();
    const directDepositGateway = JSON.parse(localStorage.getItem('directDepositGateway'));
    const authentication = useSelector((state) => state.authentication.value);
    const userId = useSelector((state) => state.userId.value);

    const handleDirectDeposit = () => {
        dispatch(setOpenDeposit(true));
        if (directDepositGateway === 'mpesa_payment') {
            dispatch(setOpenMpesaPayment(true));
        }
        if (directDepositGateway === 'marasoftpay_payment') {
            dispatch(setOpenMpesaPayment(true));
        }
        if (directDepositGateway === 'dusupay_payment') {
            dispatch(setOpenDusupayPayment(true));
        }
        if (directDepositGateway === 'wingipay_payment') {
            dispatch(setOpenWingipayPayment(true));
        }
    }


    //local storage items
    const enableSlidingText = JSON.parse(localStorage.getItem('enableSlidingText'));
    const firstWhiteSlidingText = JSON.parse(localStorage.getItem('firstWhiteSlidingText'));
    const firstAmberSlidingText = JSON.parse(localStorage.getItem('firstAmberSlidingText'));
    const secondWhiteSlidingText = JSON.parse(localStorage.getItem('secondWhiteSlidingText'));
    const secondAmberSlidingText = JSON.parse(localStorage.getItem('secondAmberSlidingText'));
    const firstBanner = JSON.parse(localStorage.getItem('firstBanner'));
    const firstBannerImage = JSON.parse(localStorage.getItem('firstBannerImage'));
    const secondBanner = JSON.parse(localStorage.getItem('secondBanner'));
    const secondBannerImage = JSON.parse(localStorage.getItem('secondBannerImage'));
    const dashboardUrl = JSON.parse(localStorage.getItem('dashboardUrl'));
    const clientSiteName = JSON.parse(localStorage.getItem('clientSiteName'));
    const cookiePolicyInfo = JSON.parse(localStorage.getItem('cookiePolicyInfo'));
    const terms = JSON.parse(localStorage.getItem('terms'));
    const marketerStatus = JSON.parse(localStorage.getItem('marketerStatus'));
    const currentYear = JSON.parse(localStorage.getItem('currentYear'));


    const banner1Image = JSON.parse(localStorage.getItem('banner1Image'));
    const banner1Link = JSON.parse(localStorage.getItem('banner1Link'));
    const banner2Image = JSON.parse(localStorage.getItem('banner2Image'));
    const banner2Link = JSON.parse(localStorage.getItem('banner2Link'));
    const banner3Image = JSON.parse(localStorage.getItem('banner3Image'));
    const banner3Link = JSON.parse(localStorage.getItem('banner3Link'));
    const banner4Image = JSON.parse(localStorage.getItem('banner4Image'));
    const banner4Link = JSON.parse(localStorage.getItem('banner4Link'));
    const banner5Image = JSON.parse(localStorage.getItem('banner5Image'));
    const banner5Link = JSON.parse(localStorage.getItem('banner5Link'));
    const banner6Image = JSON.parse(localStorage.getItem('banner6Image'));
    const banner6Link = JSON.parse(localStorage.getItem('banner6Link'));


    //context variables
    const [firstLevelAffiliatePercentage, setFirstLevelAffiliatePercentage] = useState('');
    const [secondLevelAffiliatePercentage, setSecondLevelAffiliatePercentage] = useState('');
    const [thirdLevelAffiliatePercentage, setThirdLevelAffiliatePercentage] = useState('');

    const [enableDepositSharing, setEnableDepositSharing] = useState('');
    const [firstLevelAffiliate, setFirstLevelAffiliate] = useState('');
    const [secondLevelAffiliate, setSecondLevelAffiliate] = useState('');
    const [thirdLevelAffiliate, setThirdLevelAffiliate] = useState('');
    const [minimumAffiliateWithdrawal, setMinimumAffiliateWithdrawal] = useState('');


    const [firstLevelReferrals, setFirstLevelReferrals] = useState('');
    const [secondLevelReferrals, setSecondLevelReferrals] = useState('');
    const [thirdLevelReferrals, setThirdLevelReferrals] = useState('');
    const [firstLevelReferralPendingEarnings, setFirstLevelReferralPendingEarnings] = useState('');
    const [secondLevelReferralPendingEarnings, setSecondLevelReferralPendingEarnings] = useState('');
    const [thirdLevelReferralPendingEarnings, setThirdLevelReferralPendingEarnings] = useState('');
    const [firstLevelReferralApprovedEarnings, setFirstLevelReferralApprovedEarnings] = useState('');
    const [secondLevelReferralApprovedEarnings, setSecondLevelReferralApprovedEarnings] = useState('');
    const [thirdLevelReferralApprovedEarnings, setThirdLevelReferralApprovedEarnings] = useState('');
    const [bonusReferralEarnings, setBonusReferralEarnings] = useState('');
    const [refferalAmountPaid, setRefferalAmountPaid] = useState('');
    const [availableReferralBalance, setavailableReferralBalance] = useState('');




    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const [referralProcessing, setReferralProcessing] = useState(false);
    const [allReferralInfo, setAllReferralInfo] = useState([]);
    const [data, setData] = useState([]);
    const [activeTab, setActiveTab] = React.useState("home");





    const [openTerms, setOpenTerms] = React.useState(false);
    const handleOpenTerms = () => setOpenTerms(!openTerms);

    const [open, setOpen] = React.useState(false);
    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    const [openLogin, setOpenLogin] = React.useState(true);
    const handleOpenLogin = () => setOpenLogin(!openLogin);

    const [openAffiliate, setOpenAffiliate] = React.useState(false);
    const handleOpenAffiliate = () => setOpenAffiliate(!openAffiliate);

    const [openPasswordReset, setOpenPasswordReset] = React.useState(false);
    const handleOpenPasswordReset = () => setOpenPasswordReset(!openPasswordReset);

    const [openAuth, setOpenAuth] = React.useState(false);
    const handleOpenAuth = () => setOpenAuth(!openAuth);


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    useEffect(() => {
        setEnableDepositSharing(() => JSON.parse(localStorage.getItem('enableDepositSharing')));
        setFirstLevelAffiliate(() => JSON.parse(localStorage.getItem('firstLevelAffiliate')));
        setFirstLevelAffiliatePercentage(() => JSON.parse(localStorage.getItem('firstLevelAffiliatePercentage')));
        setSecondLevelAffiliate(() => JSON.parse(localStorage.getItem('secondLevelAffiliate')));
        setSecondLevelAffiliatePercentage(() => JSON.parse(localStorage.getItem('secondLevelAffiliatePercentage')));
        setThirdLevelAffiliate(() => JSON.parse(localStorage.getItem('thirdLevelAffiliate')));
        setThirdLevelAffiliatePercentage(() => JSON.parse(localStorage.getItem('thirdLevelAffiliatePercentage')));
        setMinimumAffiliateWithdrawal(() => JSON.parse(localStorage.getItem('minimumAffiliateWithdrawal')));
    }, []);



    const switchToPasswordReset = () => {
        setOpenLogin(() => false);
        setOpenPasswordReset(() => true);
    }

    const handleOpenAuthLogin = () => {
        setOpenAuth(() => true);
        setOpenLogin(() => true);
    }

    const handleOpenAuthRegister = () => {
        setOpenAuth(() => true);
        setOpenLogin(() => false);
    }

    const errorNotify = (message) => {
        toast.error(message, {
        });
    }


    const fetchReferralInfo = async (e) => {
        try {
            setReferralProcessing(() => true);
            handleOpenAffiliate();

            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/fetchReferralInfo`,
                { userId: userId },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            setFirstLevelReferrals(() => response.data.total_first_level_affiliates);
            setSecondLevelReferrals(() => response.data.total_second_level_affiliates);
            setThirdLevelReferrals(() => response.data.total_third_level_affiliates);
            setFirstLevelReferralPendingEarnings(() => response.data.first_level_affiliate_pending_earnings);
            setSecondLevelReferralPendingEarnings(() => response.data.second_level_affiliate_pending_earnings);
            setThirdLevelReferralPendingEarnings(() => response.data.third_level_affiliate_pending_earnings);
            setFirstLevelReferralApprovedEarnings(() => response.data.first_level_affiliate_available_earnings);
            setSecondLevelReferralApprovedEarnings(() => response.data.second_level_affiliate_available_earnings);
            setThirdLevelReferralApprovedEarnings(() => response.data.third_level_affiliate_available_earnings);
            setBonusReferralEarnings(() => response.data.affiliate_bonus_earnings);
            setRefferalAmountPaid(() => response.data.total_affiliate_earnings_withdrawn);
            setavailableReferralBalance(() => response.data.total_affiliate_available_earnings);

            if (marketerStatus == true) {
                setFirstLevelAffiliatePercentage(() => response.data.first_level_affiliate_percentage);
                setSecondLevelAffiliatePercentage(() => response.data.second_level_affiliate_percentage);
                setThirdLevelAffiliatePercentage(() => response.data.third_level_affiliate_percentage);

            }

            setReferralProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            }
            handleOpenAffiliate();
            setReferralProcessing(() => false);
        }
    }





















    return (
        <>
            <div className='bg-indigo-950 '>




                <div>
                    <ToastContainer
                        bodyClassName="text-xs"
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />


                    <nav className="bg-[#212121] sticky top-0 z-50 left-0 right-0 items-stretch  ">
                        <Options
                            openLogin={openLogin}
                            handleOpenLogin={handleOpenLogin}
                            setOpenLogin={setOpenLogin}
                            switchToPasswordReset={switchToPasswordReset}
                            handleOpenAuthLogin={handleOpenAuthLogin}
                            handleOpenAuthRegister={handleOpenAuthRegister}
                            openPasswordReset={openPasswordReset}
                            setOpenPasswordReset={setOpenPasswordReset}
                            handleOpenPasswordReset={handleOpenPasswordReset}
                            openAuth={openAuth}
                            setOpenAuth={setOpenAuth}
                            handleOpenAuth={handleOpenAuth}
                            openAffiliate={openAffiliate}
                            setOpenAffiliate={setOpenAffiliate}
                            handleOpenAffiliate={handleOpenAffiliate}
                            referralProcessing={referralProcessing}
                            allReferralInfo={allReferralInfo}
                            fetchReferralInfo={fetchReferralInfo}

                            firstLevelAffiliatePercentage={firstLevelAffiliatePercentage}
                            secondLevelAffiliatePercentage={secondLevelAffiliatePercentage}
                            thirdLevelAffiliatePercentage={thirdLevelAffiliatePercentage}
                            firstLevelReferrals={firstLevelReferrals}
                            secondLevelReferrals={secondLevelReferrals}
                            thirdLevelReferrals={thirdLevelReferrals}
                            firstLevelReferralPendingEarnings={firstLevelReferralPendingEarnings}
                            secondLevelReferralPendingEarnings={secondLevelReferralPendingEarnings}
                            thirdLevelReferralPendingEarnings={thirdLevelReferralPendingEarnings}
                            firstLevelReferralApprovedEarnings={firstLevelReferralApprovedEarnings}
                            secondLevelReferralApprovedEarnings={secondLevelReferralApprovedEarnings}
                            thirdLevelReferralApprovedEarnings={thirdLevelReferralApprovedEarnings}
                            bonusReferralEarnings={bonusReferralEarnings}
                            refferalAmountPaid={refferalAmountPaid}
                            availableReferralBalance={availableReferralBalance}
                            enableDepositSharing={enableDepositSharing}
                            firstLevelAffiliate={firstLevelAffiliate}
                            secondLevelAffiliate={secondLevelAffiliate}
                            thirdLevelAffiliate={thirdLevelAffiliate}
                            minimumAffiliateWithdrawal={minimumAffiliateWithdrawal}


                        />

                    </nav>



                    {enableSlidingText == true &&
                        <div className=''>
                            <Marquee className="-mx-1" speed={20} play={true}>
                                <span className='text-gray-50 text-sm'>&nbsp; {firstWhiteSlidingText} </span>
                                <span className='text-amber-500 text-sm'>&nbsp; {firstAmberSlidingText} </span>
                                <span className='text-gray-50 text-sm'>&nbsp; {secondWhiteSlidingText} </span>
                                <span className='text-amber-500 text-sm'>&nbsp; {secondAmberSlidingText} </span>
                            </Marquee>
                        </div>

                    }

                    <div className='bg-[#090e2a] grid grid-cols-6 md:gap-4  m-0.5 md:m-4 rounded-xl '>

                        <div className='mt-2 md:mt-0 order-last md:order-first col-span-full md:col-span-2 bg-[#181c3a] rounded-xl md:h-[630px]'>
                            {/* <span>
                        + ((firstBanner == false && authentication == false) ? 'md:h-[480px]' : '')
                            + ((firstBanner == true && authentication == false) ? 'md:h-[630px]' : '')
</span> */}


                            <div className=' bg-[#181c3a] rounded-xl h-full'>
                                <MelbetTables

                                />

                            </div>
                        </div>
                        <div className='col-span-full md:col-span-4 grid  md:gap-4 rounded-xl'>

                            <div className="rounded-xl flex cursor-pointer md:hidden">
                                <span className="grow">
                                    {(authentication == false) &&
                                        <Carousel
                                            transition={{ duration: 0.5 }}
                                            autoplay={true}
                                            autoplayDelay={8000}
                                            loop={true}
                                            className="rounded-xl">
                                            <img
                                                className="rounded-xl "
                                                src={dashboardUrl + '/storage/' + banner1Image}
                                                alt={clientSiteName}
                                                onClick={handleOpenAuthRegister}
                                            />
                                            <img
                                                className="rounded-xl "
                                                src={dashboardUrl + '/storage/' + banner2Image}
                                                alt={clientSiteName}
                                                onClick={handleOpenAuthRegister}
                                            />
                                            <img
                                                className="rounded-xl "
                                                src={dashboardUrl + '/storage/' + banner3Image}
                                                alt={clientSiteName}
                                                onClick={handleOpenAuthRegister}
                                            />
                                        </Carousel>
                                    }
                                    {(authentication == true) &&
                                        <Carousel
                                            transition={{ duration: 0.5 }}
                                            autoplay={true}
                                            autoplayDelay={8000}
                                            loop={true}
                                            className="rounded-xl">
                                            <img
                                                className="rounded-xl "
                                                src={dashboardUrl + '/storage/' + banner1Image}
                                                alt={clientSiteName}
                                                onClick={banner1Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                            />
                                            <img
                                                className="rounded-xl "
                                                src={dashboardUrl + '/storage/' + banner2Image}
                                                alt={clientSiteName}
                                                onClick={banner2Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                            />
                                            <img
                                                className="rounded-xl "
                                                src={dashboardUrl + '/storage/' + banner3Image}
                                                alt={clientSiteName}
                                                onClick={banner3Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                            />
                                        </Carousel>
                                    }


                                </span>
                            </div>

                            {/* {(authentication == false) &&
                                <div className="rounded-xl flex cursor-pointer md:hidden">
                                    <span className="grow">
                                        <img
                                            className="rounded-xl "
                                            src={dashboardUrl + '/storage/' + banner1Image}
                                            alt={clientSiteName}
                                            onClick={handleOpenAuthRegister}
                                        />
                                    </span>
                                </div>
                            }

                            {(authentication == true) &&
                                <div className="rounded-xl flex cursor-pointer md:hidden">
                                    <span className="grow">
                                        <img
                                            className="rounded-xl "
                                            src={dashboardUrl + '/storage/' + banner1Image}
                                            alt={clientSiteName}
                                            onClick={fetchReferralInfo}
                                        />
                                    </span>
                                </div>
                            } */}

                            <div className=' rounded-xl'>
                                <CrashNew
                                />

                            </div>

                            <div className='grid bg-[#090e2a] rounded-xl '>
                                <div className='col-span-full  bg-[#1b1c1d] rounded-xl'>



                                    <div className="rounded-xl cursor-pointer md:hidden">
                                        <span className="grow">
                                            {(authentication == false) &&
                                                <Carousel
                                                    transition={{ duration: 0.5 }}
                                                    autoplay={true}
                                                    autoplayDelay={8000}
                                                    loop={true}
                                                    className="rounded-xl">
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner4Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner5Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner6Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                </Carousel>
                                            }
                                            {(authentication == true) &&
                                                <Carousel
                                                    transition={{ duration: 0.5 }}
                                                    autoplay={true}
                                                    autoplayDelay={8000}
                                                    loop={true}
                                                    className="rounded-xl">
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner4Image}
                                                        alt={clientSiteName}
                                                        onClick={banner4Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner5Image}
                                                        alt={clientSiteName}
                                                        onClick={banner5Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner6Image}
                                                        alt={clientSiteName}
                                                        onClick={banner6Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                </Carousel>
                                            }


                                        </span>
                                    </div>



                                    <div className="rounded-xl hidden md:flex cursor-pointer">
                                        <span className="grow">
                                            {(authentication == false) &&
                                                <Carousel
                                                    transition={{ duration: 0.5 }}
                                                    autoplay={true}
                                                    autoplayDelay={8000}
                                                    loop={true}
                                                    className="rounded-xl">
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner1Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner2Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner3Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner4Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner5Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner6Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                </Carousel>
                                            }
                                            {(authentication == true) &&
                                                <Carousel
                                                    transition={{ duration: 0.5 }}
                                                    autoplay={true}
                                                    autoplayDelay={8000}
                                                    loop={true}
                                                    className="rounded-xl">
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner1Image}
                                                        alt={clientSiteName}
                                                        onClick={banner1Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner2Image}
                                                        alt={clientSiteName}
                                                        onClick={banner2Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner3Image}
                                                        alt={clientSiteName}
                                                        onClick={banner3Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner4Image}
                                                        alt={clientSiteName}
                                                        onClick={banner4Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner5Image}
                                                        alt={clientSiteName}
                                                        onClick={banner5Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner6Image}
                                                        alt={clientSiteName}
                                                        onClick={banner6Link == 'deposit' ? handleDirectDeposit : fetchReferralInfo}
                                                    />
                                                </Carousel>
                                            }


                                        </span>
                                    </div>

                                    {/* <>
                                        {(authentication == false) &&
                                            <div className="rounded-xl cursor-pointer">
                                                <span className="grow">
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner1Image}
                                                        alt={clientSiteName}
                                                        onClick={handleOpenAuthRegister}
                                                    />
                                                </span>
                                            </div>
                                        }

                                        {(authentication == true) &&
                                            <div className="rounded-xl cursor-pointer">
                                                <span className="grow">
                                                    <img
                                                        className="rounded-xl "
                                                        src={dashboardUrl + '/storage/' + banner1Image}
                                                        alt={clientSiteName}
                                                        onClick={fetchReferralInfo}
                                                    />
                                                </span>
                                            </div>
                                        }
                                    </> */}


                                </div>

                            </div>

                        </div>

                    </div>
                    <div>
                        <div className='bg-[#212121] text-gray-200 text-xs text-center px-1 py-3'>
                            <div dangerouslySetInnerHTML={{ __html: JSON.parse(localStorage.getItem('bettingLicenseInfo')) }} />
                            <span><button onClick={handleOpenTerms} className="appearance-none text-[#ffbc00]">Terms & Conditions</button> apply | &copy;{currentYear}, {clientSiteName}. All rights reserved.</span>
                        </div>
                        <CookieConsent
                            style={{ background: "#212121" }}
                            buttonStyle={{ color: "#111111", fontSize: "13px" }}
                            expires={365}
                        >
                            <div dangerouslySetInnerHTML={{ __html: cookiePolicyInfo }} />
                        </CookieConsent>

                        <Dialog
                            open={openTerms}
                            handler={handleOpenTerms}
                            animate={{
                                mount: { scale: 1, y: 0 },
                                unmount: { scale: 0.9, y: -100 },
                            }}
                            size={"xs"}
                            className="bg-[#181c3a]"
                        >
                            <DialogHeader className="text-gray-300">Terms & Conditions</DialogHeader>
                            <DialogBody divider className="h-80 overflow-y-scroll text-xs text-gray-300">

                                <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: terms }} />

                            </DialogBody>
                            <DialogFooter>
                                <Button
                                    variant="text"
                                    color="red"
                                    onClick={handleOpenTerms}
                                    className="mr-1"
                                >
                                    <span>Cancel</span>
                                </Button>
                                <Button variant="gradient" color="green" onClick={handleOpenTerms}>
                                    <span>Confirm</span>
                                </Button>
                            </DialogFooter>
                        </Dialog>
                    </div>
                </div >



            </div >
        </>


    );

}

export default CrashGame;
